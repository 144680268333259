<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Nova Restauração"
      subtitle="Selecione o mês e entidades que serão restauradas"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <restaurar-bdgd-wizard-Tabs
        ref="restaurarBdgdParametros"
        :items="restauracoes"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import RestauracoesBdgdsService from '@/services/RestauracoesBdgdsService';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    RestaurarBdgdWizardTabs: () =>
      import('@/components/dados/restaurar-bdgd/RestaurarBdgdWizardTabs'),
    MaterialWizard: () => import('@/components/base/MaterialWizard')
  },
  data: () => ({
    tabs: ['Mês dos Dados', 'Entidades', 'Confirmação'],
    loading: false,
    restauracoes: {}
  }),
  methods: {
    confirmarExclusao() {
      return confirm(
        'Confirma a exclusão da BDGD selecionada? Essa ação não poderá ser desfeita.'
      );
    },
    async save() {
      const data = this.$refs.restaurarBdgdParametros.exportData();

      // Essa condicional verifica se a opção excluir arquivo de BDGD está marcado enquanto também pede a confirmação do salvamento ao usuário
      if (data.excluir_bdgd && !this.confirmarExclusao()) return;

      this.loading = true;
      RestauracoesBdgdsService.save(data)
        .then(() => {
          this.$toast.success('Restauração de BDGD salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['RESTAURAR_BDGD_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a restauração da BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
